.field {
    width: 100%;
}

.field {
    padding: .5rem;
    background: none;
    outline: none;
    box-shadow: none;
    @apply border-2 border-stone-300;
}

.submit {
    width: auto;
    display: block;
    border: solid 1px var(--blue-500);
    color: #fff;
    background-color: transparent;
    padding: 0 16px;
}

.checkbox {
    --border-width: 1.5px;
    position: relative;
    appearance: none;
    border: var(--border-width) solid #a6a6a6;
    height: 13px;
    width: 13px;
    
    &:checked {
        background-color: #a6a6a6;
        
        &::after {
            content: ' ';
            position: absolute;
            top: 1px;
            left: .5px;
            color: #393939;
            font-family: Line Awesome Free;
            font-weight: 900;
            background-image: url('../../../assets/images/checkbox.svg');
            background-repeat: no-repeat;
            width: calc(13px - (var(--border-width) * 2));
            height: calc(13px - (var(--border-width) * 2));
        }
    }
} 

.datalist {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
}