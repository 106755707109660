.layout {
    &--page {
        max-width: 768px;
        @apply py-12 lg:py-24 mb-12 mx-auto;
    }
}

.page {   
    &:not(&--blank) {
        @apply py-12 lg:py-24 mb-12 mx-auto;
    }
     
    &--wide {
        max-width: unset;
    }
    
    &--headless {
        @apply xl:pt-12;
    }
    
    &__header {
        max-width: 768px;
        @apply mb-12 mx-auto px-6;
    }
    
    &__title {
        @apply font-serif font-bold text-4xl lg:text-5xl text-gray-600 mb-6;
    }
    
    &--wide &__content {
        @apply container px-6;
    }
    
    &:not(.page--wide) &__content {
        max-width: 768px;
        @apply mx-auto px-6;
    }
    
    &__banner {
        max-width: 1024px;
        height: 55vh;
        @apply max-h-96 mt-12 mb-10 mx-auto object-cover;
    }
}