.list {
    padding: 0;
    background-color: var(--background);
    border-style: solid;
    border-color: #393939;
    border-width: 0px 1px;
    
    .item {
        padding: .5rem;
        border-bottom: 1px solid;
        border-color: inherit;
        cursor: pointer;
    }
}