.card {
    &__img {
        @apply mb-6;
    }
    &__date {
        
    }
    &__title {
        @apply font-bold text-xl mb-2;
    }
    &__descriptor {
       @apply font-serif text-base whitespace-normal;
    }
    
    &--md &__title {
        @apply font-semibold text-lg mb-1 text-gray-500;
    }
    &--md &__descriptor {
        @apply mb-4;
    }
}

.card-group {
    .card {
        @apply py-6 border-b-2;
    }
}