h3 {
    @apply text-2xl;
}

.list-disc {
    padding-left: 1rem;
    
    @apply list-outside;
}

.section-title {
    @apply text-2xl font-serif;
}

.section-subtitle {
    @apply text-sm;
}

.label {
    @apply uppercase text-xs tracking-wider font-medium;
}

.content {
    * {
        line-height: 1.7rem;
        hyphens: auto;
    }
    
    &:not(&--inline) > * {
        margin-top: 1rem;
    }
    
    &--compact {
        *:is(h3, h4, h5) + p {
            @apply mt-1;
        }
        
        p {
            @apply leading-5;
        }
    }
    
    p {
        font-size: .9rem;
    }
    
    ul {
        padding-left: 1.6rem;
        list-style: disc;
        
        // & + ul,
        // & + p 
        // { margin-top: 1rem; }
        
        li {
            margin-top: 0;
            margin-bottom: 0rem;
            padding-left: .5rem;
            
            &::marker {
                @apply text-red-500;
            }
        }
        
    }
    
    h3, h4, h5 {
        font-weight: 600;
        @apply font-serif text-gray-600;
    }
    
    h4 {
        font-size: 1.2rem;
    }
    
    a {
        word-break: break-all;
        @apply text-red-500 underline;
    }
}